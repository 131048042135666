/* Variables */
/* FONTS */

/* Theme Colors */
$theme_primary: #2d3e50;
$theme_primary_button_color: #517091;

$font_color: #4a4a4a;
$text_muted_color: #6c757d;
$text_invalid_feedback_color: #dc3545;
$valid_input_border_color: #28a745;
$invalid_input_border_color: #dc3545;
$focus_border_color: #129eea;

$top_color_idea: #2b3643;

$admin_side_bar_color:#3e4b5c;
$link_color:#17a2b8;

$header_color: #f27f1a;
$font: 'Open Sans', sans-serif;
$warning_color: rgb(223, 117, 20);

$upcoming_color: #85bf2e;
$cancelled_color: grey;
$complete_color: $header_color;
$edit_color: #f27f1a;
$icon_view_color: #129eea;
$icon_check_color:  #85bf2e;
$icon_blackout_color: #3e3e3e;

/*************************************************************/
/* Fonts */
/*************************************************************/
body {
    font-family: $font;
    color: $font_color;
    height:100%;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-top:0;
    margin-bottom:0.5rem;
    color:$header_color;
}
h1, .h1 {
    font-size: 2.5rem;
}
h2, .h2 {
    font-size: 2rem;
}
h3, .h3 {
    font-size: 1.75rem;
}
h4, .h4 {
    font-size: 1.5rem;
}
h5, .h5 {
    font-size: 1.25rem;
}
h6, .h6 {
    font-size: 1rem;
}
.header_color_override {
    color:$font_color !important;
}
.header-spacing {
    margin-top:1.25rem;
}
.header-bottom-spacing {
    margin-bottom:1em !important;
}
.larger-text {
    font-size:120% !important;
}
.larger-text-bold {
    font-weight:bold;
}
.larger-text-spacing {
    margin-top:1em;
}
a {
    color: $link_color;
}
a:visited {
    color: $link_color;
}

/*************************************************************/
/* Header */
/*************************************************************/
.header-wrapper {
    height:75px;
    padding:10px 30px;
    margin: auto;
}
.logo {
    height: 75px;
}

/*************************************************************/
/* Header Bar */
/*************************************************************/
.home-menu {
    background: $theme_primary;
    padding: 15px 30px;
    text-align: center;
}
.home-menu .pure-menu-selected, .home-menu .pure-menu-selected a:visited {
    color: $header_color !important;
}
.home-menu a, .home-menu a:visited {
    color: white;
}
.home-menu .login-info {
    color:#d1d1d1;
    font-size:.8em;
    text-align: center;
}
.home-menu li a:hover,
.home-menu li a:focus {
    background: none;
    border: none;
    color: #AECFE5;
}
.submenu {

}
.submenu li a {
    padding-top:0;
    padding-bottom:0;
}
@media (min-width: 48em) {
    .home-menu {
        text-align: left;
    }
    .home-menu ul {
        margin-right:4%;
    }
    .home-menu ul.floaty {
        float: right;
        margin-right:0;
    }
}

/*************************************************************/
/* General Layout */
/*************************************************************/
.secondary-bar {
    background-color:#f2f2f2;
    border-bottom:1px solid #CCCCCC;
}
.secondary-link {
    color: #4a4a4a !important;
}
.secondary-link:hover {
    color: #6a6a6a !important;
}
.w-100 {
    width:100%;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}

/*************************************************************/
/* General Layout */
/*************************************************************/
.page-container {
    min-height:100%;
    width:100%;
    position: absolute;
}
.footer {
    position:absolute;
    text-align:center;
    color:white;
    bottom:0;
    width:100%;
    background-color:$theme_primary;
}
.footer p {
    padding:.6rem 0;
    margin:0;
    font-size:.8rem;
}
.content-wrapper {
    width: 95%;
    max-width: 1980px;
    margin-right: auto;
    margin-left: auto;
    margin-top:2em;
    min-height:100%;
}
.grid-space {
    padding-right: 3rem;
}
.margin-grid-space {
    margin-right: 3rem;
}
.row-space {
    margin-top:.5rem;
}
.row-space-large {
    margin-top: 1.5rem;
}
.row-space-under-help {
    margin-top:.7rem;
}
.button-row-space {
    margin-top: 2rem;
}
.table-login-sub {
    margin-top:3rem;
}
.table-login-sub td {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-right: 1rem;
}
.left-space {
    margin-left: 2em;
}

/*************************************************************/
/* Valid/Invalid Styles */
/*************************************************************/
.invalid-feedback {
    width: 100%;
    font-size: 80%;
    color: $text_invalid_feedback_color;
    margin-top: 0.25rem;
}
.text-muted {
  color: $text_muted_color;
}
.is_valid {
    border-color: $valid_input_border_color !important;
}
.is-invalid {
    border-color: $invalid_input_border_color !important;
}
/*************************************************************/
/* Alerts */
/*************************************************************/
.alert {
    position: relative;
    padding-bottom: 1.4rem;
    border: 1px solid transparent;
}
.alert-content {
    width: 90%;
    margin-left:auto;
    margin-right:auto;
}
.alert-heading {
    color: inherit;
}
.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
.alert-primary hr {
    border-top-color: #9fcdff;
}
.alert-primary .alert-link {
    color: #002752;
}
.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.alert-success hr {
    border-top-color: #b1dfbb;
}
.alert-success .alert-link {
    color: #0b2e13;
}
.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}
.alert-info hr {
    border-top-color: #abdde5;
}
.alert-info .alert-link {
    color: #062c33;
}
.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.alert-warning hr {
    border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
    color: #533f03;
}
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.alert-danger hr {
    border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
    color: #491217;
}
.inline-warning {
    padding:1em;
}

/*************************************************************/
/* Inputs */
/*************************************************************/
.pure-button-primary {
    font-size: 110%;
}
label {
    font-weight:bold;
}
label.required::after {
    content: ' *';
    color: red;
}
label.checkbox-label {
    margin-left: 1em;
}
textarea:focus, input:focus, select:focus {
    border-color: $focus_border_color !important;
}
select:invalid {
    color:$font_color !important;
}
.button-success,
.button-error,
.button-warning,
.button-std,
.button-cancel {
    font-size: 105%;
    color: white !important;
    border-radius: 4px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.button-success {
    background: #83b819; /* this is a green */
}
.button-error {
    background: #c03a2b; /* this is a red */
}
.button-warning {
    background: rgb(223, 117, 20);
}
.button-std {
    background: $theme_primary_button_color; /* this is a light blue */
}
.button-cancel {
    background: rgb(110, 110, 110); /* this is an grey */
}
.button-in-table {
    font-size:95% !important;
}
.button-in-table-space {
    margin-left:2rem;
}
.disabled-text {
    color: #AAAAAA;
}
.same-row-input-spacing {
    padding-right:2rem;
    box-sizing:border-box;
}
.date-picker {
    margin-top:-4px; /* Fix alignment issue */
}

/*************************************************************/
/* Modify Buttons */
/*************************************************************/
%icon-shared {
    cursor: pointer;
}
.icon-space-right {
    margin-right:0.5em;
}
.check-icon {
    @extend %icon-shared;
    color: $icon_check_color;
}
.delete-icon {
    @extend %icon-shared;
    color: $text_invalid_feedback_color;
}
.cancel-icon {
    @extend %icon-shared;
    color: $cancelled_color;
}
.edit-icon {
    @extend %icon-shared;
    color: $edit_color;
}
.view-icon {
    @extend %icon-shared;
    color: $icon_view_color;
}
.blackout-icon {
    @extend %icon-shared;
    color: $icon_blackout_color;
}

/*************************************************************/
/* License Agreement */
/*************************************************************/
div.license-agreement {
    width: 100%;
    height: 500px;
    overflow-x: hidden;
    overflow-y:scroll;
    border: 1px solid #CCCCCC;
    padding: 1em;
}



/*************************************************************/
/* Table Styles */
/*************************************************************/
.cancelled-table-item {
    text-decoration: line-through;
    color:#CCC;
}
.pure-table {
    border-width: 0;
}
.pure-table thead {
	background-color: $theme_primary;
	color: #FFFFFF;
}
.table-hover tr:hover td {
    background-color: #e3e3e3;
}
.table-hover tr:nth-child(2n-1):hover td {
	background-color: #e3e3e3;
}
.table-hover-2n tr:nth-child(2n-1):hover td {
    background-color: #e3e3e3;
}
/* div version of hover table */
.pure-table-div-thead {
    background-color: $theme_primary;
	color: #FFFFFF;
    padding:0.5em;
}
.pure-table-div-row {
    padding:0.5em;
}
.pure-table-div-row:hover {
    background-color: #e3e3e3;
}
.pure-table-div-row:nth-child(2n-1):hover {
	background-color: #e3e3e3;
}
.pure-table-div-row:nth-child(2n-1):hover {
    background-color: #e3e3e3;
}

/*************************************************************/
/* Loading Overlays */
/*************************************************************/
.small-loader-spinner {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid $theme_primary;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 1.5s linear infinite;
    -webkit-animation: spin 1.5s linear infinite;
    margin: 15px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Single Row Loading */
tr.loading-table-row {
  background-image: linear-gradient(to right, transparent 50%, rgba(0, 0, 0, .05) 50%);
  background-size: 200% 100%;
  animation: loading-row 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
tr.loading-table-row td {
  opacity: .45;
  pointer-events: none;
}
@keyframes loading-row {
  0% {
    background-position: 0;
  }
  50% {
    background-position: -30%;
  }
  80% {
    background-position: -100%;
  }
  100% {
    background-position: -200%;
  }
}

/* Loading overlay for element */
.loading-overlay-element {
    position: relative;
}
.loading-overlay-element:before {
    width: 100%;
    height: 100%;
    top: 0;
    bottom:0;
    left:0;
    right:0;
    display: block;
    position: absolute;
    content: "";
    z-index: 99;
    background: rgba(255, 255,255,0.3);
}
.loading-overlay-element:after {
    position: absolute;
    top:calc(50% - 20px);
    left: calc(50% - 20px);
    z-index: 100;
    border: 8px solid darkgray;
    border-top: 8px solid $theme_primary;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.5s linear infinite;
    -webkit-animation: spin 1s linear infinite;
    content: "";
}


/*************************************************************/
/* Calendar */
/*************************************************************/
$calendar_day_width: calc(100% / 7);

.calendar {
    width: 100%;
    box-shadow: 0px 10px 8px 5px #c9c9c9;
}
.calendar-header {
    text-align:center;
    width:100%;
    background-color:#1a305e;
    color: white;
    padding-top:0.5em;
    padding-bottom:0.5em;
}
.calendar .week {
    display: flex;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #CCCCCC;
}
.calendar .week .weekday {
    background: #2c55a6;
    text-align: center;
    padding: 10px 6px;
    color: #fff;
    font-size: 1em;
    width: $calendar_day_width;
    box-sizing:border-box;
}
.calendar .week .day {
    display: flex;
    flex-direction: column;
    width: $calendar_day_width;
    padding: 5px;
    box-sizing:border-box;
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: #CCCCCC;
    height: 150px;
}
.calendar .week .today {
    background: #ffeac9;
}
.calendar .week .day p.date {
    text-align: right;
    margin: 0;
    padding: 0;
}
.calendar .week .day p.other-month {
    color: #CCCCCC;
}
.calendar .week .day .event {
    clear: both;
    display: block;
    font-size: 13px;
    border-radius: 2px;
    padding: 3px;
    margin-bottom: 3px;
    line-height: 14px;
    background: #85bf2e;
    color: white;
    text-decoration: none;
    text-align:center;
}
.calendar .week .day .event-past {
    background: #ededed;
    border: 1px solid #adadad;
    color: #7d7d7d;
}
.calendar .week .day .event-available {
    background: #6cb106;
    border: 1px solid #6cb106;
    color: white;
}
.calendar .week .day .event-available-phone {
    background: #2259ab;
    border: 1px solid #2259ab;
    color: white;
}
.calendar .week .day .event-booked {
    background: #ff5900;
    border: 1px solid #ff5900;
    color: white;
}
.calendar .week .day .event-blackout {
    background: $icon_blackout_color;
    border: 1px solid $icon_blackout_color;
    color: white;
}
.calendar-button {
    color: white;
    cursor: pointer;
    background-color: #2c55a6;
    border: 0 solid #1a305e;
    padding: 5px;
    border-radius: 2px;
}
.calendar-button:hover {
    background-color: #1F4899;
}


/*
.calendar-wrap {
    box-shadow: 0px 10px 8px 5px #c9c9c9;
}
.calendar a {
    color: #8e352e;
    text-decoration: none;
}
.calendar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}
.calendar li {
    display: block;
    float: left;
    width:14.33%;
    padding: 5px;
    box-sizing:border-box;
    border: 1px solid #ccc;
    margin-right: -1px;
    margin-bottom: -1px;
}
.calendar ul.weekdays {
    height: 40px;
    background: #2c55a6;
}
.calendar ul.weekdays li {
    text-align: center;
    text-transform: uppercase;
    line-height: 20px;
    border: none !important;
    padding: 10px 6px;
    color: #fff;
    font-size: 13px;
}
.calendar .days li {
    height: 180px;
}
.calendar .days li:hover {
    background: auto;
}
.calendar .date {
    text-align: center;
    margin-bottom: 5px;
    padding: 4px;
    background: #333;
    color: #fff;
    width: 20px;
    border-radius: 50%;
    float: right;
}

.calendar .event {
    clear: both;
    display: block;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 5px;
    line-height: 14px;
    background: #85bf2e;
    color: white;
    text-decoration: none;
    text-align:center;
}
.calendar .event-past {
    background: #ededed;
    border: 1px solid #adadad;
    color: #7d7d7d;
}
.calendar .event-available {
    background: #85bf2e;
    border: 1px solid #85bf2e;
    color: white;
}
.calendar .event-booked {
    background: #ff5900;
    border: 1px solid #ff5900;
    color: white;
}
.calendar .event-desc {
    color: #666;
    margin: 3px 0 7px 0;
    text-decoration: none;
}
.calendar .other-month {
    background: #f5f5f5;
    color: #666;
}
.calendar .calendar-today {
    background: #ffeac9;
}
.calendar-header {
    text-align:center;
    width:100%;
    background-color:#1a305e;
    color: white;
    padding-top:.1em;
    padding-bottom:.1em;
}
.calendar-button {
    background-color: #2c55a6;
    color:white !important;
}
*/


/*************************************************************/
/* Calendar Responsiveness */
/*************************************************************/
@media(max-width: 768px) {

    .calendar .weekdays, .calendar .other-month {
        display: none;
    }

    .calendar li {
        height: auto !important;
        border: 1px solid #ededed;
        width: 100%;
        padding: 10px;
        margin-bottom: -1px;
    }

    .calendar .date {
        float: none;
    }
}

/*************************************************************/
/* Status Circle */
/*************************************************************/
.status {
    display: inline-block;
    width:100%;
    text-align: center;
    border-radius: 2px;
    color: white;
    padding: 0.3em 0.5em;
    &.Upcoming {
        background-color: $upcoming_color;
    }
    &.Cancelled {
        background-color: $cancelled_color;
    }
    &.Complete {
        background-color: $complete_color;
    }

    &.Active {
        background-color: $upcoming_color;
    }
    &.Disabled {
        background-color: $cancelled_color;
    }
    &.Pending {
        background-color: $complete_color;
    }

    &.hospital {
        background-color: #bf070d;
    }
    &.clinic {
        background-color: #0b5aa5;
    }
}

/*************************************************************/
/* Icon Inputs */
/*************************************************************/
.icon-input-container {
    display: flex;
}
.input-icon {
    display: flex;
    background-color: #f2f2f2;
    border-radius: 2px 0 0 2px;
    border-style: solid;
    border-color: #CCCCCC;
    padding: 0.5em;
    align-items: center;
    justify-content: center;
    text-align: center;
    &.field {
        border-width: 1px 0 1px 1px;
    }
    &.group {
        border-width: 1px;
    }
}
.icon-input-container input {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}
%button-group-shared {

}
.button-group-empty {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(40%, rgba(255, 255, 255, 0.5)), to(rgba(0, 0, 0, .1)));
    background-image: linear-gradient(transparent, rgba(245, 245, 245, .5) 0%, rgba(255, 255, 255, .7) 40%, rgba(245, 245, 245, 1) 100%);
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #CCCCCC;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    &.selected {
        background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(40%, rgba(0, 0, 0, .05)), to(rgba(0, 0, 0, .1)));
	    background-image: linear-gradient(transparent, rgba(0, 0, 0, .05) 40%, rgba(0, 0, 0, .1));
    }
}

/*************************************************************/
/* Accordion */
/*************************************************************/
.accordion-panel {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

/*************************************************************/
/* Modal */
/*************************************************************/
/* The Modal (background) */
.modal-underlay {
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width:100%;
}
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 101; /* Sit on top */
    padding-top: 5%; /* Location of the box */
    left: auto;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    overflow: auto; /* Enable scroll if needed */
    max-height: 90vh;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    overflow: auto;
    animation-duration: 0.4s
}
/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}
@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}
/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.modal-header {
    padding: 16px 16px 5px 16px;
    background-color: #5cb85c;
    color: white;
}
.modal-body {
    padding: 2px 16px;
    padding-bottom: 20px;
}
.modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

/*************************************************************/
/* Print Booking */
/*************************************************************/

@media print {
    @page {size: portrait}
    #print-confirmation-block {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 15px;
    }
}

/*************************************************************/
/*  One time use: Styles Converted */
/*************************************************************/

.notes-separator {
    border-top:1px solid grey;
    padding-top:5px;
    border-bottom:1px solid grey;
    padding-bottom:5px;
}

/*************************************************************/
/* Admin Overview Layout */
/*************************************************************/

.overview-blocks-text {
    font-size:4em;
    font-weight:bold;
}
.overview-subblocks-text {
    font-size:1.2em;
}
.big-space {
    margin-top: 4em;
}
.small-space {
    margin-top: 1em;
}

$arrival_yes_color: #63c57f;
$arrival_no_color: #fcc46a;
$arrival_unknown_color: grey;
.overview-arrival-block {
    height:50px;
    width:100%;
    color:white;
    background-color: red;
    text-align:center;
    display: flex;

    .arrival-block {
        height:100%;
        line-height:50px;

        &.yes {
            background-color: $arrival_yes_color;
        }
        &.no {
            background-color: $arrival_no_color;
        }
        &.unknown {
            background-color: $arrival_unknown_color;
        }
    }
}
.overview-arrival-legend {
    display:inline-block;
    height:1em;
    width:1em;
    border-radius:0.5em;
    &.yes {
        background-color: $arrival_yes_color;
    }
    &.no {
        background-color: $arrival_no_color;
    }
    &.unknown {
        background-color: $arrival_unknown_color;
    }
}
.overview-arrival-legend-text {
    display:inline;
    margin-left:0.3em;
}
.overview-issue {

    &.critical {
        color: $text_invalid_feedback_color;
    }
    &.warning {
        color: $warning_color;
    }
    &.info {

    }
}

/*************************************************************/
/* Div Table */
/*************************************************************/
.div-table {
    display:  table;
    width:auto;
    background-color:#eee;
    border:1px solid  #666666;
    border-spacing:5px;
    padding:15px;

    &.row
    {
       display:table-row;
       width:auto;
    }

    &.cell
    {
        display:table-column;
        width:200px;
        background-color:#ccc;
    }
}